import { olsVsRidgeData, absoluteFunctionBase, absoluteFunctionWithGrad, absoluteFunctionSlider, absoluteFunctionSubgradientConditionSlider, lassoSubGDHistPlot } from "../../../../src/content/machine_learning_models/data/lassoRidgeData";
import { f2FunctionBase, f2SubgradientPlotZero, f2SubgradientPlotInteractive, setupCodeSubgradientDescent, setupCodeSubgradientDescentEps, setupCodeSubgradientDescentReinforcement } from "../../../../src/content/machine_learning_math/data/subgradient_data";
import { polyOlsRidge } from "../../../../src/content/machine_learning_models/data/lassoRidgeData";
import * as React from 'react';
export default {
  olsVsRidgeData,
  absoluteFunctionBase,
  absoluteFunctionWithGrad,
  absoluteFunctionSlider,
  absoluteFunctionSubgradientConditionSlider,
  lassoSubGDHistPlot,
  f2FunctionBase,
  f2SubgradientPlotZero,
  f2SubgradientPlotInteractive,
  setupCodeSubgradientDescent,
  setupCodeSubgradientDescentEps,
  setupCodeSubgradientDescentReinforcement,
  polyOlsRidge,
  React
};