import { olsVsRidgeData, olsVsRidgeDataTrainTestOls, olsVsRidgeDataTrainTestOlsRidge } from "../../../../src/content/machine_learning_models/data/lassoRidgeData";
import { olsVsRidgeYearsScale, olsVsRidgeStandardize, olsVsRidgeDataTrainTestOlsRidgeDays, olsVsRidgeDataTrainTestOlsRidgeYears, olsVsRidgeDataTrainTestOlsRidgeDecades, olsVsRidgeDataTrainTestOlsRidgeStandardized } from "../../../../src/content/dataset_optimization/data/standardizationData";
import * as React from 'react';
export default {
  olsVsRidgeData,
  olsVsRidgeDataTrainTestOls,
  olsVsRidgeDataTrainTestOlsRidge,
  olsVsRidgeYearsScale,
  olsVsRidgeStandardize,
  olsVsRidgeDataTrainTestOlsRidgeDays,
  olsVsRidgeDataTrainTestOlsRidgeYears,
  olsVsRidgeDataTrainTestOlsRidgeDecades,
  olsVsRidgeDataTrainTestOlsRidgeStandardized,
  React
};