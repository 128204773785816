const colors = ['#33638D','#54C667','#6a1b9a','#FF8900']

const nonStandardizedXOne = [-9.27, -7.32, -3.04, -4.88, -4.13, -9.26, -5.66, -8.47, -8.7, -3.82, -8.56, -3.47, -5.42, -7.6, -3.73, -7.05, -5.61, -7.44, -9.27, -7.21, -8.68, -3.76, -3.25, -7.13, -5.99, -6.13, -6.26, -5.11, -6.72, -8.61, -6.74, -3.5, -3.37, -7.06, -9.8, -4.13, -8.79, -6.45, -5.55, -7.31, -7.03, -6.11, -3.02, -6.23, -3.8, -7.77, -7.23, -9.01, -3.51, -7.11, -8.06, -8.23, -4.88, -5.39, -7.39, -5.75, -4.02, -8.15, -9.22, -6.81, -9.12, -9.23, -6.42, -5.09, -8.26, -6.65, -9.81, -5.94, -7.08, -7.37, -5.96, -7.08, -5.2, -9.49, -4.2, -8.61, -8.97, -3.81, -4.61, -3.37, -7.5, -3.11, -9.12, -8.46, -3.17, -9.05, -6.91, -6.35, -8.99, -4.6, -7.47, -7.57, -8.62, -3.43, -3.94, -4.93, -3.63, -5.11, -9.32, -5.17]
const nonStandardizedXTwo =[8.52, 3.59, 8.15, 9.02, 9.89, 9.75, 9.44, 9.08, 7.08, 4.22, 8.51, 4.1, 9.2, 8.78, 7.38, 9.93, 8.61, 9.39, 6.18, 9.58, 8.74, 3.7, 9.54, 8.79, 6.85, 4.98, 5.29, 9.57, 9.41, 4.73, 8.38, 3.82, 9.23, 6.63, 8.27, 5.06, 8.73, 5.35, 8.61, 5.7, 8.96, 5.61, 9.98, 8.66, 9.85, 5.93, 8.27, 8.77, 7.62, 9.72, 8.72, 9.21, 6.87, 7.86, 7.8, 9.3, 8.37, 4.56, 5.64, 7.04, 4.58, 6.66, 5.34, 8.81, 4.1, 8.85, 7.12, 5.91, 6.96, 4.23, 5.32, 6.73, 7.28, 6.32, 8.65, 4.77, 8.6, 3.71, 9.14, 5.5, 5.87, 9.71, 5.78, 7.18, 3.22, 6.4, 3.56, 8.98, 7.74, 8.03, 9.63, 3.91, 4.4, 4.47, 4.44, 3.63, 6.0, 6.92, 5.35, 8.31]

const yOne = [25, 33, 47, 40, 36, 48, 25, 41, 40, 35, 42, 37, 42, 49, 31, 48, 40, 44, 41, 41, 29, 44, 39, 34, 38, 26, 25, 46, 30, 28, 25, 48, 31, 28, 43, 28, 31, 42, 31, 32, 32, 29, 39, 28, 34, 38, 49, 47, 46, 32, 34, 42, 34, 42, 39, 44, 44, 29, 40, 37, 38, 43, 33, 44, 48, 45, 34, 40, 47, 33, 34, 39, 25, 30, 37, 38, 29, 47, 39, 48, 32, 27, 49, 30, 40, 27, 27, 31, 27, 34, 48, 31, 45, 42, 44, 43, 46, 47, 47, 34]
const yTwo = [18.31, 15.8, 23.45, 23.43, 23.19, 21.87, 23.75, 29.03, 23.61, 37.75, 27.24, 37.09, 17.3, 19.88, 37.66, 35.18, 21.44, 19.31, 31.52, 23.98, 33.01, 24.16, 19.22, 23.19, 21.76, 35.74, 39.66, 20.73, 24.81, 21.78, 19.96, 22.38, 18.71, 33.72, 21.41, 26.1, 19.53, 21.54, 23.04, 19.45, 39.84, 28.49, 23.78, 24.31, 15.84, 37.1, 34.64, 18.44, 24.78, 16.74, 30.18, 33.33, 17.39, 28.82, 34.09, 18.69, 25.03, 27.75, 31.34, 30.64, 20.45, 19.12, 31.72, 33.07, 19.5, 25.65, 25.97, 30.86, 21.86, 29.94, 15.12, 15.14, 19.39, 27.32, 27.03, 35.23, 37.97, 31.39, 22.96, 27.89, 19.62, 16.03, 32.42, 27.82, 36.25, 29.95, 16.92, 25.38, 27.47, 31.68, 32.24, 25.55, 37.7, 34.29, 26.82, 32.62, 15.32, 33.2, 16.61, 32.06]

const standardizedXOne = [1.3, 0.61, -1.2, 0.23, -0.44, 0.23, -1.47, 0.43, 0.1, -0.58, 1.37, 0.35, -0.89, -1.35, -0.05, 1.37, 0.2, -1.69, 0.5, 0.02, 1.24, -1.19, -1.45, 1.3, 0.41, 0.81, 0.92, 0.45, -1.14, 1.39, 1.02, 0.54, 0.64, 0.9, -1.54, -1.58, 1.36, 1.43, 0.94, 0.79, -0.34, -0.45, -0.97, 0.64, -0.49, -1.69, 0.18, -1.56, 1.42, 0.6, -1.65, -0.19, -1.44, -1.47, 1.26, 1.24, 0.79, 0.79, -0.49, -1.65, -0.84, 0.38, 1.27, 0.0, -0.12, 0.82, 0.37, -0.95, 1.42, -1.42, -1.48, -0.24, 0.57, -1.25, 1.37, 0.03, 0.82, -0.63, 0.18, -0.43, -1.16, 0.66, -1.27, -0.89, 1.37, -0.8, 0.42, 0.73, -1.77, -0.71, 0.66, 1.43, 1.29, -1.16, -0.76, 0.21, 1.2, -0.69, 0.79, -0.18]
const standardizedXTwo = [-1.64, -0.92, -1.19, 1.47, 0.0, 1.21, 0.86, 1.32, 0.18, 1.45, -1.46, -0.84, -0.46, 1.76, -0.4, 0.83, -0.9, -1.66, 1.87, -1.69, 0.39, -1.13, -1.63, -1.15, 0.52, 1.2, 1.25, 0.43, -1.32, -0.24, -0.8, 0.9, 0.27, 1.3, 1.1, 1.59, -0.12, 0.92, 1.64, 0.17, -1.16, -0.17, -1.07, -0.03, 1.8, -1.1, 0.77, -1.03, 0.29, -0.31, -0.57, -0.1, -1.37, 1.82, -0.18, 0.11, -0.27, -0.77, -0.5, 1.07, -0.19, 0.14, -0.46, 1.23, -0.91, -0.69, -0.98, -0.81, -0.61, 0.57, 0.05, -0.71, 1.1, -0.26, 1.69, 0.3, -0.86, -0.02, 0.73, -0.03, -0.13, 0.1, -0.46, 1.53, -1.57, 0.66, -1.32, 1.49, -1.0, -0.41, 1.37, -0.85, -1.58, 0.95, 1.59, -0.82, 0.06, -0.51, 0.13, -0.87]



const nonStandardizedData = [
    {
    x: nonStandardizedXOne,
    y: yOne,
    color: colors[0],
    type: `bar`,
    showlegend: true,
    hoverlabel: {
      font: {color: "#fff"}
    },
  },

  {
    x: nonStandardizedXTwo,
    y: yTwo,
    color: colors[1],
    type: `bar`,
    showlegend: true,
    hoverlabel: {
      font: {color: "#fff"}
    },
  }
]

const standardizedData = [
    {
    x: standardizedXOne,
    y: yOne,
    color: colors[0],
    type: `bar`,
    showlegend: true,
    hoverlabel: {
      font: {color: "#fff"}
    },
  },

  {
    x: standardizedXTwo,
    y: yTwo,
    color: colors[1],
    type: `bar`,
    showlegend: true,
    hoverlabel: {
      font: {color: "#fff"}
    },
  }
]
  
  const standardLayout = {
    xaxis: {
            range: [-10, 10],
            showgrid: true,
            gridcolor: "rgba(255,255,255,0.05)",
            zeroline: true,
            fixedrange: true,
            showline: true,
            autotick: true,
            ticks: '',
            showticklabels: true
            },
    yaxis: {
            gridcolor: "rgba(255,255,255,0.05)",
            zeroline: true,
            showline: true,
            autotick: true,
            ticks: '',
            showticklabels: true
            },
    margin: { t: 80, r: 80, b: 80, l: 80 },
    paper_bgcolor: `rgba(0, 0, 0, 0)`,
    plot_bgcolor: `rgba(0, 0, 0, 0)`,
    font: {
      color: `rgba(255,255,255,0.5)`,
      size: 16,
    }
}
  
export const  nonStandardizedPlot = {
    data: nonStandardizedData,
    layout: standardLayout,
    props: {
      fixedRange:true
    }
}

export const standardizedPlot = {
    data: standardizedData,
    layout: standardLayout,
    props: {
      fixedRange:true
    }
}

  

const olsVsRidgeYTrain = [40. , 42. , 46. , 37.]
const olsVsRidgeYTest =  [40. , 38. , 39.8]

const olsVsRidgeXTrainDays = [456.25, 365.0, 273.75, 547.5]
const olsVsRidgeXTestDays = [638.75, 547.5 , 273.75]
const olsVsRidgeXIntervalDays = [273.75, 638.75]

const olsVsRidgeXTrainYears = [1.25, 1.  , 0.75, 1.5]
const olsVsRidgeXTestYears = [1.75, 1.5 , 0.75]
const olsVsRidgeXIntervalYears = [0.75, 1.75]

const olsVsRidgeXTrainDecades = [0.125, 0.1  , 0.075, 0.15 ]
const olsVsRidgeXTestDecades = [0.175, 0.15 , 0.075]

const olsVsRidgeXIntervalDecades = [0.075, 0.175]



const olsVsRidgeOlsPredsDays = [45.6, 34.0 ]
const olsVsRidgeOlsPredsYears = [45.6, 34.0 ]
const olsVsRidgeOlsPredsDecades = [45.6, 34.0 ]


const olsVsRidgeRidgePredsDays = [45.6, 34.0]
const olsVsRidgeRidgePredsYears =   [42.29, 39.52]
const olsVsRidgeRidgePredsDecades = [41.26,  41.23]


const olsVsRidgeXStandardizedTrain = [0.098, -0.588, -1.275, 0.784]
const olsVsRidgeXStandardizedTest = [1.471, 0.784, -1.275]

const olsVsRidgePredsStandardizedIntervalOls = [45.6, 34.0]
const olsVsRidgePredsStandardizedIntervalRidge = [44.73, 35.45]

const olsVsRidgeXIntervalStandard = [-1.34,  2.24]


const olsVsRidgeDataYears = [{
    x: olsVsRidgeXTrainYears,
    y: olsVsRidgeYTrain,
    type: `scatter`,
    mode: "markers",
    name: "Train",
    showlegend: true,
    hoverlabel: {
      font: {color: "#fff"}
    },
    hovertemplate:
          "age: %{x}y<br>" +
          "price: %{y}€<br>" +
          "<extra></extra>",
    marker: { 
      size:15,
      color: colors[0],
      line: {
        color: '#fff',
        width: 0.5
    }},
  }, 
  {
    x: olsVsRidgeXTestYears,
    y: olsVsRidgeYTest,
    type: 'scatter',
    mode: "markers",
    name: "Test",
    showlegend: true,
    hoverlabel: {
      font: {color: "#000"}
    },
    hovertemplate:
          "age: %{x}y<br>" +
          "price: %{y}€<br>" +
          "<extra></extra>",
    marker: { 
      size:15,
      color: colors[1],
      line: {
        color: '#000',
        width: 0.5
    }},
  },
  {
  x: olsVsRidgeXIntervalYears,
  y: olsVsRidgeOlsPredsYears,
  type: `scatter`,
  mode: "lines",
  name: "OLS",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[2],
    line: {
      color: '#fff',
      width: 0.5
  }},
  },
  {
  x: olsVsRidgeXIntervalYears,
  y: olsVsRidgeRidgePredsYears,
  type: `scatter`,
  mode: "lines",
  name: "Ridge",
  showlegend: true,
  hoverlabel: {
    font: {color: "#000"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[3],
    line: {
      color: '#000',
      width: 0.5
  }},
}]


const olsVsRidgeDataDays = [{
  x: olsVsRidgeXTrainDays,
  y: olsVsRidgeYTrain,
  type: `scatter`,
  mode: "markers",
  name: "Train",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[0],
    line: {
      color: '#fff',
      width: 0.5
  }},
}, 
{
  x: olsVsRidgeXTestDays,
  y: olsVsRidgeYTest,
  type: 'scatter',
  mode: "markers",
  name: "Test",
  showlegend: true,
  hoverlabel: {
    font: {color: "#000"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[1],
    line: {
      color: '#000',
      width: 0.5
  }},
  },
  {
  x: olsVsRidgeXIntervalDays,
  y: olsVsRidgeOlsPredsDays,
  type: `scatter`,
  mode: "lines",
  name: "OLS",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[2],
    line: {
      color: '#fff',
      width: 0.5
  }},
  },
  {
  x: olsVsRidgeXIntervalDays,
  y: olsVsRidgeRidgePredsDays,
  type: `scatter`,
  mode: "lines",
  name: "Ridge",
  showlegend: true,
  hoverlabel: {
    font: {color: "#000"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[3],
    line: {
      color: '#000',
      width: 0.5
  }},
}]

const olsVsRidgeDataDecades= [{
  x: olsVsRidgeXTrainDecades,
  y: olsVsRidgeYTrain,
  type: `scatter`,
  mode: "markers",
  name: "Train",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[0],
    line: {
      color: '#fff',
      width: 0.5
  }},
}, 
{
  x: olsVsRidgeXTestDecades,
  y: olsVsRidgeYTest,
  type: 'scatter',
  mode: "markers",
  name: "Test",
  showlegend: true,
  hoverlabel: {
    font: {color: "#000"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[1],
    line: {
      color: '#000',
      width: 0.5
  }},
  },
  {
  x: olsVsRidgeXIntervalDecades,
  y: olsVsRidgeOlsPredsDecades,
  type: `scatter`,
  mode: "lines",
  name: "OLS",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[2],
    line: {
      color: '#fff',
      width: 0.5
  }},
  },
  {
  x: olsVsRidgeXIntervalDecades,
  y: olsVsRidgeRidgePredsDecades,
  type: `scatter`,
  mode: "lines",
  name: "Ridge",
  showlegend: true,
  hoverlabel: {
    font: {color: "#000"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[3],
    line: {
      color: '#000',
      width: 0.5
  }},
}]

const olsVsRidgeDataStandardizedPreds = [{
  x: olsVsRidgeXStandardizedTrain,
  y: olsVsRidgeYTrain,
  type: `scatter`,
  mode: "markers",
  name: "Train",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[0],
    line: {
      color: '#fff',
      width: 0.5
  }},
}, 
{
  x: olsVsRidgeXStandardizedTest,
  y: olsVsRidgeYTest,
  type: 'scatter',
  mode: "markers",
  name: "Test",
  showlegend: true,
  hoverlabel: {
    font: {color: "#000"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[1],
    line: {
      color: '#000',
      width: 0.5
  }},
  },
  {
  x: olsVsRidgeXIntervalStandard,
  y: olsVsRidgePredsStandardizedIntervalOls,
  type: `scatter`,
  mode: "lines",
  name: "OLS",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[2],
    line: {
      color: '#fff',
      width: 0.5
  }},
  },
  {
  x: olsVsRidgeXIntervalStandard,
  y: olsVsRidgePredsStandardizedIntervalRidge,
  type: `scatter`,
  mode: "lines",
  name: "Ridge",
  showlegend: true,
  hoverlabel: {
    font: {color: "#000"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[3],
    line: {
      color: '#000',
      width: 0.5
  }},
}]

export const olsVsRidgeDataTrainTestOlsRidgeDecades = {
  data: olsVsRidgeDataDecades,
  props: {
    yAxisTitle: "price (€)",
    xAxisTitle: "age (decades)",
    plotTitle: "Figure Prices | Decades",
    fixedRange:true
  }
}


export const olsVsRidgeDataTrainTestOlsRidgeYears = {
  data: olsVsRidgeDataYears,
  props: {
    yAxisTitle: "price (€)",
    xAxisTitle: "age (years)",
    plotTitle: "Figure Prices | Years",
    fixedRange:true
  }
}

export const olsVsRidgeDataTrainTestOlsRidgeDays = {
  data: olsVsRidgeDataDays,
  props: {
    yAxisTitle: "price (€)",
    xAxisTitle: "age (days)",
    plotTitle: "Figure Prices | Days",
    fixedRange:true
  }
}

export const olsVsRidgeDataTrainTestOlsRidgeStandardized = {
  data: olsVsRidgeDataStandardizedPreds,
  props: {
    yAxisTitle: "price (€)",
    xAxisTitle: "age (days)",
    plotTitle: "Figure Prices | Standardized",
    fixedRange:true
  }
}





let currentScaleIdx = 0
const scaledDatasets = [olsVsRidgeDataDays, olsVsRidgeDataYears, olsVsRidgeDataDecades] 
const titles = ["days", "years", "decades"]
const getCurrentScale = () => titles[currentScaleIdx]

const getChangeScale = () => {

  const changeScale = (data) => {

    currentScaleIdx = (currentScaleIdx + 1 ) % 3
    const newScaleData = scaledDatasets[currentScaleIdx]
    return newScaleData

  }
  return changeScale
}

const getChangeDatasetResetStep = () => {

  const changeScaleResetStep = (data) => {

    currentScaleIdx = (currentScaleIdx + 1 ) % 3
    const newScaleData = noPredsDatasets[currentScaleIdx]

    standardizationStep = 0

    return newScaleData

  }
  return changeScaleResetStep
}

export const olsVsRidgeYearsScale = {
  data: olsVsRidgeDataDays,
  props: {
    changeDataFunctions: {"change scaling": getChangeScale()},
    fixedRange:true,
    plotTitle: "Different Data Scalings Visualized",
    metricInfo: {
      "current scale": getCurrentScale,
    },
    transition: {
      duration: 0,
      easing: 'cubic-in-out'
    }
  }
}
  

// here, standardize test dataset as well!
// else it doesn't work well..

const olsVsRidgeDataDaysNoPreds = [olsVsRidgeDataDays[0], olsVsRidgeDataDays[1]]
const olsVsRidgeDataYearsNoPreds = [olsVsRidgeDataYears[0], olsVsRidgeDataYears[1]]
const olsVsRidgeDataDecadesNoPreds = [olsVsRidgeDataDecades[0], olsVsRidgeDataDecades[1]]

const noPredsDatasets = [olsVsRidgeDataDaysNoPreds, olsVsRidgeDataYearsNoPreds, olsVsRidgeDataDecadesNoPreds]

const olsVsRidgeDataDaysNoPredsZeroMeanTrain = [  45.625,  -45.625, -136.875,  136.875]
const olsVsRidgeDataYearsNoPredsZeroMeanTrain = [ 0.125, -0.125, -0.375,  0.375]
const olsVsRidgeDataDecadesNoPredsZeroMeanTrain = [ 0.0125, -0.0125, -0.0375,  0.0375]

const olsVsRidgeDataDaysNoPredsZeroMeanTest = [ 228.12,  136.88, -136.88]
const olsVsRidgeDataYearsNoPredsZeroMeanTest = [ 0.62,  0.38, -0.38]
const olsVsRidgeDataDecadesNoPredsZeroMeanTest = [ 0.06,  0.04, -0.04]

const olsVsRidgeDataNoPredsStandardizedTrain = [ 0.45, -0.45, -1.34,  1.34]
const olsVsRidgeDataNoPredsStandardizedTest = [ 2.24,  1.34, -1.34]

const olsVsRidgeDataDaysZeroMean = [{
  x: olsVsRidgeDataDaysNoPredsZeroMeanTrain,
  y: olsVsRidgeYTrain,
  type: `scatter`,
  mode: "markers",
  name: "Train",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[0],
    line: {
      color: '#fff',
      width: 0.5
  }},
},
{
  x: olsVsRidgeDataDaysNoPredsZeroMeanTest,
  y: olsVsRidgeYTest,
  type: 'scatter',
  mode: "markers",
  name: "Test",
  showlegend: true,
  hoverlabel: {
    font: {color: "#000"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[1],
    line: {
      color: '#000',
      width: 0.5
  }},
},
]

const olsVsRidgeDataYearsZeroMean = [{
  x: olsVsRidgeDataYearsNoPredsZeroMeanTrain,
  y: olsVsRidgeYTrain,
  type: `scatter`,
  mode: "markers",
  name: "Train",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[0],
    line: {
      color: '#fff',
      width: 0.5
  }},
}
]

const olsVsRidgeDataDecadesZeroMean = [{
  x: olsVsRidgeDataDecadesNoPredsZeroMeanTrain,
  y: olsVsRidgeYTrain,
  type: `scatter`,
  mode: "markers",
  name: "Train",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[0],
    line: {
      color: '#fff',
      width: 0.5
  }},
}]

const olsVsRidgeDataStandardized = [{
  x: olsVsRidgeDataNoPredsStandardizedTrain,
  y: olsVsRidgeYTrain,
  type: `scatter`,
  mode: "markers",
  name: "Train",
  showlegend: true,
  hoverlabel: {
    font: {color: "#fff"}
  },
  hovertemplate:
        "age: %{x}y<br>" +
        "price: %{y}€<br>" +
        "<extra></extra>",
  marker: { 
    size:15,
    color: colors[0],
    line: {
      color: '#fff',
      width: 0.5
  }},
}]

const olsVsRidgeYearsUnchangedMeanStd = [
  olsVsRidgeDataYears[0],
  { 
    x: [1.125, 1.125],
    y: [0, 100],
    type: 'scatter',
    mode: "lines",
    name: "Mean",
    showlegend: true,
    hoverlabel: {
      font: {color: "#000"}
    },
    hovertemplate:
          "age: %{x}y<br>" +
          "price: %{y}€<br>" +
          "<extra></extra>",
    marker: { 
      size:15,
      color: colors[1],
      line: {
        color: '#000',
        width: 0.5
    }},
  },
  { 
    x: [1.125],
    y: [100],
    width: [0.28*2],
    type: 'bar',
    name: "Std",
    opacity: 0.5,
    showlegend: true,
    hoverlabel: {
      font: {color: "#000"}
    },
    hovertemplate:
          "<extra></extra>",
    marker: { 
      size:15,
      color: colors[2],
      line: {
        color: '#000',
        width: 0.5
    }},
  },
]

const olsVsRidgeYearsZeroMeanStd = [
  olsVsRidgeDataYearsZeroMean[0],
  { 
    x: [0, 0],
    y: [0, 100],
    type: 'scatter',
    mode: 'lines',
    name: "Mean",
    showlegend: true,
    hoverlabel: {
      font: {color: "#000"}
    },
    hovertemplate:
          "age: %{x}y<br>" +
          "price: %{y}€<br>" +
          "<extra></extra>",
    marker: { 
      size:15,
      color: colors[1],
      line: {
        color: '#000',
        width: 0.5
    }},
  },
  { 
    x: [0],
    y: [100],
    width: [0.28*2],
    type: 'bar',
    name: "Std",
    opacity: 0.5,
    showlegend: true,
    hoverlabel: {
      font: {color: "#000"}
    },
    hovertemplate:
          "age: %{x}y<br>" +
          "price: %{y}€<br>" +
          "<extra></extra>",
    marker: { 
      size:15,
      color: colors[2],
      line: {
        color: '#000',
        width: 0.5
    }},
  },
]


const olsVsRidgeYearsStandardMeanStd = [
  olsVsRidgeDataStandardized[0],
  { 
    x: [0, 0],
    y: [0, 100],
    type: 'scatter',
    mode: 'lines',
    name: "Mean",
    showlegend: true,
    hoverlabel: {
      font: {color: "#000"}
    },
    hovertemplate:
          "age: %{x}y<br>" +
          "price: %{y}€<br>" +
          "<extra></extra>",
    marker: { 
      size:15,
      color: colors[1],
      line: {
        color: '#000',
        width: 0.5
    }},
  },
  { 
    x: [0],
    y: [100],
    width: [1*2],
    type: 'bar',
    opacity: 0.5,
    name: "Std",
    showlegend: true,
    hoverlabel: {
      font: {color: "#000"}
    },
    hovertemplate:
          "age: %{x}y<br>" +
          "price: %{y}€<br>" +
          "<extra></extra>",
    marker: { 
      size:15,
      color: colors[2],
      line: {
        color: '#000',
        width: 0.5
    }},
  },
]
  



// const olsVsRidgeDataYearsNoPreds = [olsVsRidgeDataYears[0], olsVsRidgeDataYears[1]]

const noPredsDatasetsZeroMean = [olsVsRidgeDataDaysZeroMean, olsVsRidgeDataYearsZeroMean, olsVsRidgeDataDecadesZeroMean] 

let standardizationStep = 0
const steps = ["unchanged", "zero mean", "zero mean, std of one"]
const getCurrentStep = () => steps[standardizationStep]


const getPerformStandardizationStep = () => {

  const performStandardizationStep = (data) => {

    standardizationStep = (standardizationStep + 1) % 3
    let newStepData

    if (standardizationStep==0) {
      newStepData = olsVsRidgeDataYearsNoPreds //noPredsDatasets[currentScaleIdx]
    } else if (standardizationStep==1) {
      newStepData = olsVsRidgeDataYearsZeroMean //noPredsDatasetsZeroMean[currentScaleIdx]
    } else {
      newStepData = olsVsRidgeDataStandardized
    }

    return newStepData

  }
  return performStandardizationStep
}

const standardizeLayoutDays = {
  transition: {
    duration: 750,
    easing: 'cubic-in-out'
  },
  xaxis: {
          range: [-700, 700],
          showgrid: true,
          gridcolor: "rgba(255,255,255,0.05)",
          zeroline: true,
          fixedrange: false,
          showline: true,
          autotick: true,
          ticks: '',
          showticklabels: true
          },
  yaxis: {
          range: [36, 48],
          gridcolor: "rgba(255,255,255,0.05)",
          zeroline: true,
          fixedrange: true,
          showline: true,
          autotick: true,
          ticks: '',
          showticklabels: true
          },
  margin: { t: 80, r: 80, b: 80, l: 80 },
  paper_bgcolor: `rgba(0, 0, 0, 0)`,
  plot_bgcolor: `rgba(0, 0, 0, 0)`,
  font: {
    color: `rgba(255,255,255,0.5)`,
    size: 16,
  }
}


const standardizeLayoutYears = {
  transition: {
    duration: 750,
    easing: 'cubic-in-out'
  },
  xaxis: {
          range: [-3, 3],
          showgrid: true,
          gridcolor: "rgba(255,255,255,0.05)",
          zeroline: true,
          fixedrange: false,
          showline: true,
          autotick: true,
          ticks: '',
          showticklabels: true
          },
  yaxis: {
          range: [36, 48],
          gridcolor: "rgba(255,255,255,0.05)",
          zeroline: true,
          fixedrange: true,
          showline: true,
          autotick: true,
          ticks: '',
          showticklabels: true
          },
  margin: { t: 80, r: 80, b: 80, l: 80 },
  paper_bgcolor: `rgba(0, 0, 0, 0)`,
  plot_bgcolor: `rgba(0, 0, 0, 0)`,
  font: {
    color: `rgba(255,255,255,0.5)`,
    size: 16,
  }
}

const standardizeLayoutDecades = {
  transition: {
    duration: 750,
    easing: 'cubic-in-out'
  },
  xaxis: {
          range: [-1,1],
          showgrid: true,
          gridcolor: "rgba(255,255,255,0.05)",
          zeroline: true,
          fixedrange: false,
          title: "Years",
          showline: true,
          autotick: true,
          ticks: '',
          showticklabels: true
          },
  yaxis: {
          range: [36, 48],
          gridcolor: "rgba(255,255,255,0.05)",
          zeroline: true,
          fixedrange: true,
          showline: true,
          title: "Price in €",
          autotick: true,
          ticks: '',
          showticklabels: true
          },
  margin: { t: 80, r: 80, b: 80, l: 80 },
  paper_bgcolor: `rgba(0, 0, 0, 0)`,
  plot_bgcolor: `rgba(0, 0, 0, 0)`,
  font: {
    color: `rgba(255,255,255,0.5)`,
    size: 16,
  }
}


const standardizeLayoutsUnchanged = [standardizeLayoutDays, standardizeLayoutYears, standardizeLayoutDecades]
const dataYearsWithMeanStdArray = [olsVsRidgeYearsUnchangedMeanStd ,olsVsRidgeYearsZeroMeanStd,olsVsRidgeYearsStandardMeanStd ]

const getDataYearsWithMeanStd = () => {
  standardizationStep = (standardizationStep + 1) % 3
  return dataYearsWithMeanStdArray[standardizationStep]
}

export const olsVsRidgeStandardize = {
  data: olsVsRidgeYearsUnchangedMeanStd,
  layout: standardizeLayoutYears,
  props: {
    changeDataFunctions: {
                          "next step": getDataYearsWithMeanStd,
                          //"next dataset": getChangeDatasetResetStep()*/
                        },
    /*changeLayoutFunctions: {
                             "next dataset": getNextLayoutFunction,
    },*/
    fixedRange:true,
    plotTitle: "Standardization Visualized",
    metricInfo: {
      "current step": getCurrentStep,
      //"current dataset": getCurrentScale,
    },
    dragMode: "zoom",
    xAxisTitle: "years",
    yAxisTitle: "price in €"
  }
}
  