import { treatAsUTC } from "../../../utils"

const colors = ['#33638D','#54C667','#6a1b9a','#FF8900']
const threeFunctionColors =  ['#54C667','#FF8900','#6a1b9a','#33638D']
// prettier-ignore

const dataXMarkersOnly = {
  markers: ['1', '2', '3', '3', '4', '4', '5'],
}

const dataX = {
  markers: ['1', '2', '3', '3', '4', '4', '5'],
  lines: ['1','2','3','4','5'],
}

const dataY = {
  markers: [80,170,100,220,200,270,500], 
  lines: [60,120,180,240,300],
}

const dataXThreeFunctions = {
  f: ['1','2','3','4','5'],
  g: ['1','2','3','4','5'],
  h: ['1','2','3','4','5'],
  data: ['1', '2', '3', '3', '4', '4', '5'],
}

const dataYThreeFunctions = {
  f: [60, 120, 180, 240, 300],
  g: [70, 140, 210, 280, 350],
  h: [55, 105, 155, 205, 255],
  data: [80,170,100,220,200,270,500], 
}

const threeFunctionModes = {
  f: "lines",
  g: "lines",
  h: "lines",
  data: "markers" 
}

const threeFunctionTextColors = {
  f: "#000",
  g: "#000",
  h: "#fff",
  data: "#fff" 
}

const complexitiesTextColors = {
  'O(n^2)': "#fff",
  'O(n^2.4)': "#000",
  'O(n^3)': "#fff",
}

const modes = {
  markers: "markers",
  lines: "lines" 
}

const textColors = {
  markers: "#fff",
  lines: "#000",
  third: '#000'
}

export const markerData = {
  data: Object.keys(dataXMarkersOnly).map((key, index) => ({
    x: dataX[key],
    y: dataY[key],
    type: `scatter`,
    mode: modes[key],
    name: key,
    showlegend: false,
    hoverlabel: {
      font: {color: threeFunctionTextColors[key]}
    },
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    plotTitle: "Housing Prices"
  }
}

export const markerLinesData = {
  data: Object.keys(dataX).map((key, index) => ({
    x: dataX[key],
    y: dataY[key],
    type: `scatter`,
    mode: modes[key],
    name: key,
    hoverlabel: {
      font: {color: textColors[key]}
    },
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    plotTitle: "Housing Prices"
  }
}

export const threeLinesData = {
  data: Object.keys(dataXThreeFunctions).map((key, index) => ({
    x: dataXThreeFunctions[key],
    y: dataYThreeFunctions[key],
    type: `scatter`,
    mode: threeFunctionModes[key],
    name: key,
    hoverlabel: {
      font: {color: threeFunctionTextColors[key]}
    },
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: threeFunctionColors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    plotTitle: "Housing Prices"
  }
}

export const quadraticPlot = {
  data: [{
    x: [-5.  , -4.75, -4.5 , -4.25, -4.  , -3.75, -3.5 , -3.25, -3.  ,
      -2.75, -2.5 , -2.25, -2.  , -1.75, -1.5 , -1.25, -1.  , -0.75,
      -0.5 , -0.25,  0.  ,  0.25,  0.5 ,  0.75,  1.  ,  1.25,  1.5 ,
       1.75,  2.  ,  2.25,  2.5 ,  2.75,  3.  ,  3.25,  3.5 ,  3.75,
       4.  ,  4.25,  4.5 ,  4.75],
    y: [25.0, 22.5625, 20.25, 18.0625, 16.0, 14.0625, 12.25, 10.5625, 9.0, 7.5625, 6.25, 5.0625, 4.0, 3.0625, 2.25, 1.5625, 1.0, 0.5625, 0.25, 0.0625, 0.0, 0.0625, 0.25, 0.5625, 1.0, 1.5625, 2.25, 3.0625, 4.0, 5.0625, 6.25, 7.5625, 9.0, 10.5625, 12.25, 14.0625, 16.0, 18.0625, 20.25, 22.5625],
    type: `scatter`,
    mode: 'lines',
    name: "q",
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[0],
      line: {
        color: '#fff',
        width: 1
    }},
  }],
}

export const quadraticPlotDerivative = {
  data: [{
    x: [-5, 5],
    y: [-5, 5],
    type: `scatter`,
    mode: 'lines',
    name: "q'",
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[0],
      line: {
        color: '#fff',
        width: 1
    }},
  }],
}

export const quadraticWithDerivative = {
  data: [
    {
      x: [-5.  , -4.75, -4.5 , -4.25, -4.  , -3.75, -3.5 , -3.25, -3.  ,
        -2.75, -2.5 , -2.25, -2.  , -1.75, -1.5 , -1.25, -1.  , -0.75,
        -0.5 , -0.25,  0.  ,  0.25,  0.5 ,  0.75,  1.  ,  1.25,  1.5 ,
         1.75,  2.  ,  2.25,  2.5 ,  2.75,  3.  ,  3.25,  3.5 ,  3.75,
         4.  ,  4.25,  4.5 ,  4.75],
      y: [25.0, 22.5625, 20.25, 18.0625, 16.0, 14.0625, 12.25, 10.5625, 9.0, 7.5625, 6.25, 5.0625, 4.0, 3.0625, 2.25, 1.5625, 1.0, 0.5625, 0.25, 0.0625, 0.0, 0.0625, 0.25, 0.5625, 1.0, 1.5625, 2.25, 3.0625, 4.0, 5.0625, 6.25, 7.5625, 9.0, 10.5625, 12.25, 14.0625, 16.0, 18.0625, 20.25, 22.5625],
      type: `scatter`,
      mode: 'lines',
      name: "q",
      hovertemplate:
              "price: %{y}k<br>" +
              "#bedrooms: %{x}<br>" +
              "<extra></extra>",
      marker: { 
        size:15,
        color: colors[0],
        line: {
          color: '#fff',
          width: 1
      }},
    },
    {
      x: [-5, 5],
      y: [-5, 5],
      type: `scatter`,
      mode: 'lines',
      name: "q'",
      hovertemplate:
              "price: %{y}k<br>" +
              "#bedrooms: %{x}<br>" +
              "<extra></extra>",
      marker: { 
        size:15,
        color: colors[1],
        line: {
          color: '#fff',
          width: 1
      }},
    }],
}

export const quadraticWithDerivativeWithPoint = {
  data: [
    {
      x: [-5.  , -4.75, -4.5 , -4.25, -4.  , -3.75, -3.5 , -3.25, -3.  ,
        -2.75, -2.5 , -2.25, -2.  , -1.75, -1.5 , -1.25, -1.  , -0.75,
        -0.5 , -0.25,  0.  ,  0.25,  0.5 ,  0.75,  1.  ,  1.25,  1.5 ,
         1.75,  2.  ,  2.25,  2.5 ,  2.75,  3.  ,  3.25,  3.5 ,  3.75,
         4.  ,  4.25,  4.5 ,  4.75],
      y: [25.0, 22.5625, 20.25, 18.0625, 16.0, 14.0625, 12.25, 10.5625, 9.0, 7.5625, 6.25, 5.0625, 4.0, 3.0625, 2.25, 1.5625, 1.0, 0.5625, 0.25, 0.0625, 0.0, 0.0625, 0.25, 0.5625, 1.0, 1.5625, 2.25, 3.0625, 4.0, 5.0625, 6.25, 7.5625, 9.0, 10.5625, 12.25, 14.0625, 16.0, 18.0625, 20.25, 22.5625],
      type: `scatter`,
      mode: 'lines',
      name: "q",
      hovertemplate:
              "price: %{y}k<br>" +
              "#bedrooms: %{x}<br>" +
              "<extra></extra>",
      marker: { 
        size:15,
        color: colors[0],
        line: {
          color: '#fff',
          width: 1
      }},
    },
    {
    x: [-5, 5],
    y: [-5, 5],
    type: `scatter`,
    mode: 'lines',
    name: "q'",
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[1],
      line: {
        color: '#fff',
        width: 1
    }},
  },
  {
    x: [-2.25],
    y: [5.0625],
    type: `scatter`,
    mode: 'markers',
    name: "q(point)",
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[0],
      line: {
        color: '#fff',
        width: 1
    }},
  },
  {
    x: [-2.25],
    y: [-2.25],
    type: `scatter`,
    mode: 'markers',
    name: "q'(point)",
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    hoverlabel: {
      font: {color:  '#000'}
    },
    marker: { 
      size:15,
      color: colors[1],
      line: {
        color: '#fff',
        width: 1
    }},
  },

],
}

const dataYComplexities = {
  'O(n^2)': [1, 676, 2601, 5776, 10201, 15876, 22801, 30976, 40401, 51076, 63001, 76176, 90601, 106276, 123201, 141376, 160801, 181476, 203401, 226576, 251001, 276676, 303601, 331776, 361201, 391876, 423801, 456976, 491401, 527076, 564001, 602176, 641601, 682276, 724201, 767376, 811801, 857476, 904401, 952576],
  'O(n^2.4)': [1, 2488, 12536, 32655, 64620, 109872, 169646, 245036, 337031, 446539, 574403, 721409, 888299, 1075776, 1284508, 1515133, 1768261, 2044480, 2344356, 2668435, 3017244, 3391296, 3791087, 4217100, 4669804, 5149658, 5657106, 6192586, 6756522, 7349332, 7971422, 8623193, 9305035, 10017333, 10760462, 11534794, 12340691, 13178511, 14048606, 14951320],
  'O(n^3)': [1, 17576, 132651, 438976, 1030301, 2000376, 3442951, 5451776, 8120601, 11543176, 15813251, 21024576, 27270901, 34645976, 43243551, 53157376, 64481201, 77308776, 91733851, 107850176, 125751501, 145531576, 167284151, 191102976, 217081801, 245314376, 275894451, 308915776, 344472101, 382657176, 423564751, 467288576, 513922401, 563559976, 616295051, 672221376, 731432701, 794022776, 860085351, 929714176],
}

const dataYComplexitiesNoO3 = {
  'O(n^2)': [1, 676, 2601, 5776, 10201, 15876, 22801, 30976, 40401, 51076, 63001, 76176, 90601, 106276, 123201, 141376, 160801, 181476, 203401, 226576, 251001, 276676, 303601, 331776, 361201, 391876, 423801, 456976, 491401, 527076, 564001, 602176, 641601, 682276, 724201, 767376, 811801, 857476, 904401, 952576],
  'O(n^2.4)': [1, 2488, 12536, 32655, 64620, 109872, 169646, 245036, 337031, 446539, 574403, 721409, 888299, 1075776, 1284508, 1515133, 1768261, 2044480, 2344356, 2668435, 3017244, 3391296, 3791087, 4217100, 4669804, 5149658, 5657106, 6192586, 6756522, 7349332, 7971422, 8623193, 9305035, 10017333, 10760462, 11534794, 12340691, 13178511, 14048606, 14951320]
}

export const complexitiesData = {
  data: Object.keys(dataYComplexities).map((key, index) => ({
    x: ['1', '26', '51', '76', '101', '126', '151', '176', '201', '226', '251', '276', '301', '326', '351', '376', '401', '426', '451', '476', '501', '526', '551', '576', '601', '626', '651', '676', '701', '726', '751', '776', '801', '826', '851', '876', '901', '926', '951', '976'],
    y: dataYComplexities[key],
    type: `scatter`,
    mode: 'lines',
    name: key,
    hovertemplate:
            "num. of features: %{x}<br>" +
            "num. of computations: %{y}<br>" +
            "<extra></extra>",
    hoverlabel: {
      font: {color:  complexitiesTextColors[key]}
    },
    marker: { 
      size:15,
      color: colors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  props: {
    yAxisTitle: "#computations",
    xAxisTitle: "#features",
    plotTitle: "Complexities"
  },
}


export const complexitiesDataWithoutO3 = {
  data: Object.keys(dataYComplexitiesNoO3).map((key, index) => ({
    x: ['1', '26', '51', '76', '101', '126', '151', '176', '201', '226', '251', '276', '301', '326', '351', '376', '401', '426', '451', '476', '501', '526', '551', '576', '601', '626', '651', '676', '701', '726', '751', '776', '801', '826', '851', '876', '901', '926', '951', '976'],
    y: dataYComplexities[key],
    type: `scatter`,
    mode: 'lines',
    name: key,
    hovertemplate:
      "num. of features: %{x}<br>" +
      "num. of computations: %{y}<br>" +
      "<extra></extra>",
    hoverlabel: {
      font: {color: complexitiesTextColors[index]}
    },
    marker: { 
      size:15,
      color: colors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  props: {
    yAxisTitle: "#computations",
    xAxisTitle: "#features",
    plotTitle: "Complexities"
  },
}

function f(m:number,b:number,i:number) {
  return m*i+b
}

function msomr(points:number[],preds:number[]){
    let result = 0
    for (let i = 0; i < points.length; i++){
      let curPoint = points[i]
      let curPred = preds[i]  
      result += (curPred-curPoint)**2
    }
    result = result / points.length
    return result
}

function getZ(m:number,b:number,points_x:number[],points_y:number[]) {
        
    let f_values = points_x.map(x => f(m,b,x))
  
    let z = msomr(points_y,f_values)
    
    return z

}

// points
const points_y = [80,170,100,220,200,270,500]
const points_x = [1, 2, 3, 3, 4, 4, 5]

// bias (intercept) terms
const b = [-150., -135., -120., -105.,  -90.,  -75.,  -60.,  -45.,  -30.,
  -15.,    0.,   15.,   30.,   45.,   60.,   75.,   90.,  105.,
  120.,  135.,  150.]

// slopes
const m = [  0. ,   7.5,  15. ,  22.5,  30. ,  37.5,  45. ,  52.5,  60. ,
  67.5,  75. ,  82.5,  90. ,  97.5, 105. , 112.5, 120. , 127.5,
 135. , 142.5, 150. ]
 
const z = b.map(curB => m.map(curM => getZ(curM,curB,points_x,points_y)))

export const somr_for_every_m_and_b = {
  data: [
    {
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "MSE: %{z}<br>" +
              "<extra></extra>",
      colorscale: 'YlGnBu',
      z: z,
      x: m,
      y: b,
      type: `surface`,
      contours: {
        z: {
          show: true,
          usecolormap: true,
          highlightcolor: `white`,
          project: { z: true },
        },
      },
    },
  ],
  style: { height: `30em` },
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    dragMode: "",
    scene: {
      camera: {
        center:{x:0, y:0, z:0},
        eye:{x:-0.85, y:-1.5, z:1.25}}, 
        xaxis:{title: 'm'},
        yaxis:{title: 'b'},
        zaxis:{title: 'MSE'},
    },
    margin: { t: 20, r: 20, b: 20, l: 20 }
  }
}


export const somr_for_every_m_and_b_with_minimum = {
  data: [
    {
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "MSE: %{z}<br>" +
              "<extra></extra>",
      colorscale: 'YlGnBu',
      z: z,
      x: m,
      y: b,
      type: `surface`,
      contours: {
        z: {
          show: true,
          usecolormap: true,
          highlightcolor: `white`,
          project: { z: true },
        },
      },
    },
    {
      z: [5691.73],
      x: [84.737],
      y:[-46.316],
      mode: 'markers',
      showlegend: false,
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#8A2BE2",
      },
      hovertemplate:
              "<b>Minimum</b><br>" +
              "b: %{x}k<br>" +
              "m: %{y}k<br>" +
              "MSE: %{z}<br>" +
              "<extra></extra>",
      marker: {
        size: 12,
        color: '#8A2BE2',
        line: {
          color: 'rgba(217, 217, 217, 0.14)',
          width: 0.5
        },
        opacity: 1
      },
      type: 'scatter3d'
    },
  ],
  style: { height: `30em` },
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    dragMode: "",
    scene: {
      camera: {
        center:{x:0, y:0, z:0},
        eye:{x:-0.85, y:-1.5, z:1.25}},
        xaxis:{title: 'm'},
        yaxis:{title: 'b'},
        zaxis:{title: 'MSE'},
    },
    margin: { t: 20, r: 20, b: 20, l: 20 }
  }
}

function subtract_arrays(a,b) {
  let result = a.map(function(item, index) {
    return item - b[index];
  })
  return result
}

function multiply_arrays(a,b) {
  let result = a.map(function(item, index) {
    return item * b[index];
  })
  return result
}

function sum_array(a) {
 let result = a.reduce((prev, current) => prev + current, 0);
 return result
}


function mse_derivative_m(pointsX,pointsY,preds){
  
  let temporary_array = multiply_arrays(pointsX, (subtract_arrays(pointsY,preds)))

  let secondTerm = sum_array(temporary_array) //sum array

  let result =  -(2/pointsY.length) * secondTerm

  return result
}

function mse_derivative_b(pointsX,pointsY,preds){
  
  let temporary_array = (subtract_arrays(pointsY,preds))

  let secondTerm = sum_array(temporary_array) //sum array

  let result =  -(2/pointsY.length) * secondTerm

  return result
}

function getZPartialDerivativeM(m,b,points_x,points_y) {
    
  let f_values = points_x.map(x => f(m,b,x))


  let z = mse_derivative_m(points_x,points_y,f_values)
  
  return z

}

function getZPartialDerivativeB(m,b,points_x,points_y) {
    
  let f_values = points_x.map(x => f(m,b,x))


  let z = mse_derivative_b(points_x,points_y,f_values)
  
  return z

}

/*
-46.31578947 +  84.73684211*x

>>>mean_sosr(data,f_ideal)
5691.72932330827


mse_derivative_m(points_x,points_y,preds_o)
1.314284158330078e-7
mse_derivative_b(points_x,points_y,preds_o)
3.714282164375098e-8


*/

const z_partial_m = b.map(curB => m.map(curM => getZPartialDerivativeM(curM,curB,points_x,points_y)))

const z_partial_b = b.map(curB => m.map(curM => getZPartialDerivativeB(curM,curB,points_x,points_y)))

export const mse_plot_partial_derivative_m = {
      data: [
        {
          hoverlabel: {
            font: {color: "#fff"},
            bgcolor: "#17225D",
          },
          hovertemplate:
                  "m: %{x}k<br>" +
                  "b: %{y}k<br>" +
                  "dm: %{z}k<br>" +
                  "<extra></extra>",
          colorscale: 'YlGnBu',
          z: z_partial_m,
          x: m,
          y: b,
          type: `surface`,
          contours: {
            z: {
              show: true,
              usecolormap: true,
              highlightcolor: `white`,
              project: { z: true },
            },
          },
        },

        /*{
          hoverlabel: {
            font: {color: "#fff"},
            bgcolor: "#17225D",
          },
          hovertemplate:
                  "m: %{x}k<br>" +
                  "b: %{y}k<br>" +
                  "db: %{z}k<br>" +
                  "<extra></extra>",
          z: [-1645,1782],
          x: [0,150],
          y: [0,0],
          type: 'scatter3d',
          mode: 'lines',
          line: {
            width: 6,
            color: 'red',
          }
        },*/

        {
          z: [-1645.7142857142856],
          x: [0],
          y:[0],
          mode: 'markers',
          showlegend: false,
          hoverlabel: {
            font: {color: "#000"},
            bgcolor: "#FF9F0E",
          },
          hovertemplate:
                  "<b>We are here!</b><br>" +
                  "b: %{x}k<br>" +
                  "m: %{y}k<br>" +
                  "dm: %{z}k<br>" +
                  "<extra></extra>",
          marker: {
            size: 12,
            color: '#FF9F0E',
            line: {
              color: 'rgba(217, 217, 217, 0.14)',
              width: 0.5
            },
            opacity: 1
          },
          type: 'scatter3d'
        },
        {
          z: [1.314284158330078e-7],
          x:  [84.73684211],
          y: [-46.31578947],
          mode: 'markers',
          showlegend: false,
          hoverlabel: {
            font: {color: "#fff"},
            bgcolor: "#8A2BE2",
          },
          hovertemplate:
                  "<b>Optimal Solution</b><br>" +
                  "b: %{x}k<br>" +
                  "m: %{y}k<br>" +
                  "dm: ~ 1.31e-7 k<br>" +
                  "<extra></extra>",
          marker: {
            size: 12,
            color: '#8A2BE2',
            line: {
              color: 'rgba(217, 217, 217, 0.14)',
              width: 0.5
            },
            opacity: 1
          },
          type: 'scatter3d'
        },
      {
        hoverlabel: {
          font: {color: "#fff"},
          bgcolor: "#17225D",
        },
        hovertemplate:
                "m: %{x}k<br>" +
                "b: %{y}k<br>" +
                "db: %{z}k<br>" +
                "<extra></extra>",
        z: [[0,0],[0,0]],
        x: [0,140],
        y: [-150,150],
        type: `surface`,
        colorscale: 'Jet',
        showscale: false,
        opacity:0.5,
        color: "#000",
        contours: {
          z: {
            show: true,
            usecolormap: true,
            highlightcolor: `white`,
            project: { z: true },
          },
        },
      }
      ],
      style: { height: `30em` },
      props: {
        yAxisTitle: "price in thousand $",
        xAxisTitle: "number of bedrooms",
        dragMode: "",
        scene: {
          camera: {
            center:{x:0, y:0, z:0},
            eye:{x:-1.5, y:-1.25, z:0.5}},
            xaxis:{title: 'm'},
            yaxis:{title: 'b'},
            zaxis:{title: 'dm'},
        },
        margin: { t: 20, r: 20, b: 20, l: 20 }
      }
}

export const mse_plot_partial_derivative_b = {
  data: [
    {
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      colorscale: 'YlGnBu',
      z: z_partial_b,
      x: m,
      y: b,
      type: `surface`,
      contours: {
        z: {
          show: true,
          usecolormap: true,
          highlightcolor: `white`,
          project: { z: true },
        },
      },
    },
    /*{
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      z: [-800, 800],
      x: [84.73684211,84.73684211],
      y: [-46.31578947,-46.31578947],
      type: 'scatter3d',
      mode: 'lines',
      line: {
        width: 6,
        color: '#347963',
      }
    },*/
    /*{
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      z: [-268.5,331.4],
      x: [75,75],
      y: [-150,150],
      type: 'scatter3d',
      mode: 'lines',
      line: {
        width: 6,
        color: 'red',
      }
    },*/
    /*
    {
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      z: [0,0],
      x: [84.73684211,84.73684211],
      y: [-150,150],
      type: 'scatter3d',
      mode: 'lines',
      line: {
        width: 6,
        color: '#347963',
      }
    },
    {
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      z: [0,0],
      x: [0,140],
      y: [-46.31578947,-46.31578947],
      type: 'scatter3d',
      mode: 'lines',
      line: {
        width: 6,
        color: '#347963',
      }
    },
    */{
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      z: [[0,0],[0,0]],
      x: [0,140],
      y: [-150,150],
      type: `surface`,
      colorscale: 'Jet',
      contours: {
        z: {
          show: true,
          usecolormap: true,
          highlightcolor: `white`,
          project: { z: true },
        },
      },
      //colorscale: 'YlGnBu',
      showscale: false,
      opacity:0.5,
      color: "#000",
    },
    {
      z: [-440.0],
      x: [0],
      y:[0],
      mode: 'markers',
      showlegend: false,
      hoverlabel: {
        font: {color: "#000"},
        bgcolor: "#FF9F0E",
      },
      hovertemplate:
              "<b>We are here!</b><br>" +
              "b: %{x}k<br>" +
              "m: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      marker: {
        size: 12,
        color: '#FF9F0E',
        line: {
          color: 'rgba(217, 217, 217, 0.14)',
          width: 0.5
        },
        opacity: 1
      },
      type: 'scatter3d'
    },
    {
      z: [3.714282164375098e-8],
      x:  [84.73684211],
      y: [-46.31578947],
      mode: 'markers',
      showlegend: false,
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#8A2BE2",
      },
      hovertemplate:
              "<b>Optimal Solution</b><br>" +
              "b: %{x}k<br>" +
              "m: %{y}k<br>" +
              "db: ~ 3.71e-8 k<br>" +
              "<extra></extra>",
      marker: {
        size: 12,
        color: '#8A2BE2',
        line: {
          color: 'rgba(217, 217, 217, 0.14)',
          width: 0.5
        },
        opacity: 1
      },
      type: 'scatter3d'
    },
  ],
  style: { height: `30em` },
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    showLegend:false,
    dragMode: "",
    scene: {
      camera: {
        center:{x:0, y:0, z:0},
        eye:{x:-1.5, y:-1.25, z:0.5}},
        xaxis:{title: 'm'},
        yaxis:{title: 'b'},
        zaxis:{title: 'db'},
    },
    margin: { t: 20, r: 20, b: 20, l: 20 }
  }
}

export const msePlotWithPoints = {
  data: [
    {
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      colorscale: 'YlGnBu',
      z: z,
      x: m,
      y: b,
      type: `surface`,
      contours: {
        z: {
          show: true,
          usecolormap: true,
          highlightcolor: `white`,
          project: { z: true },
        },
      },
    },
    {
      z: [5691.73],
      x: [84.737],
      y:[-46.316],
      mode: 'markers',
      showlegend: false,
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#8A2BE2",
      },
      hovertemplate:
              "<b>Minimum</b><br>" +
              "b: %{x}k<br>" +
              "m: %{y}k<br>" +
              "MSE: %{z}<br>" +
              "<extra></extra>",
      marker: {
        size: 12,
        color: '#8A2BE2',
        line: {
          color: 'rgba(217, 217, 217, 0.14)',
          width: 0.5
        },
        opacity: 1
      },
      type: 'scatter3d'
    },
    {
      z: [65228.57142857143],
      x: [0],
      y: [0],
      mode: 'markers',
      showlegend: false,
      hoverlabel: {
        font: {color: "#000"},
        bgcolor: "#FF9F0E",
      },
      hovertemplate:
              "<b>We are here!</b><br>" +
              "b: %{x}k<br>" +
              "m: %{y}k<br>" +
              "MSE: %{z}<br>" +
              "<extra></extra>",
      marker: {
        size: 12,
        color: '#FF9F0E',
        line: {
          color: 'rgba(217, 217, 217, 0.14)',
          width: 0.5
        },
        opacity: 1
      },
      type: 'scatter3d'
    },
  ],
  style: { height: `30em` },
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    dragMode: "",
    scene: {
      camera: {
        center:{x:0, y:0, z:0},
        eye:{x:-0.85, y:-1.5, z:1.25}},
        xaxis:{title: 'm'},
        yaxis:{title: 'b'},
        zaxis:{title: 'MSE'},
    },
    margin: { t: 20, r: 20, b: 20, l: 20 }
  }
}

export const mse_crossplot = {
  data: [
    {
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      colorscale: 'YlGnBu',
      z: z,
      x: m,
      y: b,
      type: `surface`,
      contours: {
        z: {
          show: true,
          usecolormap: true,
          highlightcolor: `white`,
          project: { z: true },
        },
      },
    },
    {
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#17225D",
      },
      hovertemplate:
              "m: %{x}k<br>" +
              "b: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      colorscale: 'YlGnBu',
      z: z_partial_m,
      x: m,
      y: b,
      type: `surface`,
      showscale:false,
      contours: {
        z: {
          show: true,
          usecolormap: true,
          highlightcolor: `white`,
          project: { z: true },
        },
      },
    },
    {
      z: [-440.0],
      x: [0],
      y:[0],
      mode: 'markers',
      showlegend: false,
      hoverlabel: {
        font: {color: "#000"},
        bgcolor: "#FF9F0E",
      },
      hovertemplate:
              "<b>We are here!</b><br>" +
              "b: %{x}k<br>" +
              "m: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      marker: {
        size: 12,
        color: '#FF9F0E',
        line: {
          color: 'rgba(217, 217, 217, 0.14)',
          width: 0.5
        },
        opacity: 1
      },
      type: 'scatter3d'
    },
    {
      z: [3.714282164375098e-8],
      x:  [84.73684211],
      y: [-46.31578947],
      mode: 'markers',
      showlegend: false,
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#8A2BE2",
      },
      hovertemplate:
              "<b>Optimal Solution</b><br>" +
              "b: %{x}k<br>" +
              "m: %{y}k<br>" +
              "db: %{z}k<br>" +
              "<extra></extra>",
      marker: {
        size: 12,
        color: '#8A2BE2',
        line: {
          color: 'rgba(217, 217, 217, 0.14)',
          width: 0.5
        },
        opacity: 1
      },
      type: 'scatter3d'
    },
    {
      z: [5691.73],
      x: [84.737],
      y:[-46.316],
      mode: 'markers',
      showlegend: false,
      hoverlabel: {
        font: {color: "#fff"},
        bgcolor: "#8A2BE2",
      },
      hovertemplate:
              "<b>Minimum</b><br>" +
              "b: %{x}k<br>" +
              "m: %{y}k<br>" +
              "MSE: %{z}<br>" +
              "<extra></extra>",
      marker: {
        size: 12,
        color: '#8A2BE2',
        line: {
          color: 'rgba(217, 217, 217, 0.14)',
          width: 0.5
        },
        opacity: 1
      },
      type: 'scatter3d'
    },
    {
      z: [65228.57142857143],
      x: [0],
      y: [0],
      mode: 'markers',
      showlegend: false,
      hoverlabel: {
        font: {color: "#000"},
        bgcolor: "#FF9F0E",
      },
      hovertemplate:
              "<b>We are here!</b><br>" +
              "b: %{x}k<br>" +
              "m: %{y}k<br>" +
              "MSE: %{z}<br>" +
              "<extra></extra>",
      marker: {
        size: 12,
        color: '#FF9F0E',
        line: {
          color: 'rgba(217, 217, 217, 0.14)',
          width: 0.5
        },
        opacity: 1
      },
      type: 'scatter3d'
    },
  ],
  style: { height: `30em` },
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    dragMode: "",
    scene: {
      camera: {
        center:{x:0, y:0, z:0},
        eye:{x:-0.85, y:-1.5, z:1.25}},
        xaxis:{title: 'm'},
        yaxis:{title: 'b'},
        zaxis:{title: 'db'},
    },
    margin: { t: 20, r: 20, b: 20, l: 20 }
  }
}
  


const dataYIdeal = {
  markers: [80,170,100,220,200,270,500], 
  lines: [40.0, 122.5, 205.0, 287.5, 370.0],
}


export const markerLinesDataIdeal = {
  data: Object.keys(dataX).map((key, index) => ({
    x: dataX[key],
    y: dataYIdeal[key],
    type: `scatter`,
    mode: modes[key],
    name: key,
    hoverlabel: {
      font: {color: textColors[key]}
    },
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    plotTitle: "Housing Prices"
  }
}

var yDataLearningRates= {
  "low":[65.228, 64.938, 64.65 , 64.363, 64.077, 63.793, 63.51 , 63.229,
       62.949, 62.67 , 62.393, 62.117, 61.842, 61.569, 61.297, 61.027,
       60.758, 60.49 , 60.223, 59.958, 59.694, 59.431, 59.17 , 58.91 ,
       58.651, 58.394, 58.137, 57.882, 57.629, 57.376, 57.125, 56.875,
       56.626, 56.379, 56.132, 55.887, 55.643, 55.4  , 55.159, 54.919,
       54.679, 54.441, 54.205, 53.969, 53.734, 53.501, 53.269, 53.038,
       52.808, 52.579, 52.351, 52.125, 51.899, 51.675, 51.452, 51.23 ,
       51.009, 50.789, 50.57 , 50.352, 50.135, 49.92 , 49.705, 49.491,
       49.279, 49.067, 48.857, 48.648, 48.439, 48.232, 48.026, 47.82 ,
       47.616, 47.413, 47.21 , 47.009, 46.809, 46.609, 46.411, 46.214,
       46.017, 45.822, 45.627, 45.434, 45.241, 45.05 , 44.859, 44.669,
       44.481, 44.293, 44.106, 43.92 , 43.735, 43.55 , 43.367, 43.185,
       43.003, 42.822, 42.643, 42.464, 42.286, 42.109, 41.933, 41.757,
       41.583, 41.409, 41.236, 41.064, 40.893, 40.723, 40.554, 40.385,
       40.217, 40.05 , 39.884, 39.719, 39.554, 39.391, 39.228, 39.066,
       38.905, 38.744, 38.584, 38.426, 38.267, 38.11 , 37.953, 37.798,
       37.643, 37.488, 37.335, 37.182, 37.03 , 36.879, 36.728, 36.578,
       36.429, 36.281, 36.134, 35.987, 35.841, 35.695, 35.55 , 35.406,
       35.263, 35.121, 34.979, 34.837, 34.697, 34.557, 34.418, 34.28 ,
       34.142, 34.005, 33.868, 33.733, 33.598, 33.463, 33.329, 33.196,
       33.064, 32.932, 32.801, 32.671, 32.541, 32.411, 32.283, 32.155,
       32.028, 31.901, 31.775, 31.649, 31.525, 31.4  , 31.277, 31.154,
       31.031, 30.91 , 30.788, 30.668, 30.548, 30.428, 30.31 , 30.191,
       30.074, 29.957, 29.84 , 29.724, 29.609, 29.494, 29.38 , 29.266,
       29.153, 29.04 , 28.928, 28.817, 28.706, 28.596, 28.486, 28.377],
  "ideal":[65.228, 14.99 ,  7.542,  6.433,  6.263,  6.232,  6.222,  6.214,
    6.208,  6.201,  6.195,  6.188,  6.182,  6.176,  6.17 ,  6.164,
    6.158,  6.152,  6.146,  6.141,  6.135,  6.129,  6.124,  6.118,
    6.113,  6.108,  6.103,  6.097,  6.092,  6.087,  6.082,  6.077,
    6.072,  6.068,  6.063,  6.058,  6.054,  6.049,  6.045,  6.04 ,
    6.036,  6.031,  6.027,  6.023,  6.019,  6.015,  6.011,  6.007,
    6.003,  5.999,  5.995,  5.991,  5.987,  5.984,  5.98 ,  5.976,
    5.973,  5.969,  5.966,  5.962,  5.959,  5.955,  5.952,  5.949,
    5.946,  5.942,  5.939,  5.936,  5.933,  5.93 ,  5.927,  5.924,
    5.921,  5.918,  5.915,  5.913,  5.91 ,  5.907,  5.904,  5.902,
    5.899,  5.896,  5.894,  5.891,  5.889,  5.886,  5.884,  5.881,
    5.879,  5.877,  5.874,  5.872,  5.87 ,  5.867,  5.865,  5.863,
    5.861,  5.859,  5.857,  5.855,  5.853,  5.851,  5.849,  5.847,
    5.845,  5.843,  5.841,  5.839,  5.837,  5.835,  5.833,  5.832,
    5.83 ,  5.828,  5.826,  5.825,  5.823,  5.821,  5.82 ,  5.818,
    5.816,  5.815,  5.813,  5.812,  5.81 ,  5.809,  5.807,  5.806,
    5.804,  5.803,  5.802,  5.8  ,  5.799,  5.798,  5.796,  5.795,
    5.794,  5.792,  5.791,  5.79 ,  5.789,  5.787,  5.786,  5.785,
    5.784,  5.783,  5.781,  5.78 ,  5.779,  5.778,  5.777,  5.776,
    5.775,  5.774,  5.773,  5.772,  5.771,  5.77 ,  5.769,  5.768,
    5.767,  5.766,  5.765,  5.764,  5.763,  5.762,  5.761,  5.76 ,
    5.759,  5.759,  5.758,  5.757,  5.756,  5.755,  5.755,  5.754,
    5.753,  5.752,  5.751,  5.751,  5.75 ,  5.749,  5.748,  5.748,
    5.747,  5.746,  5.746,  5.745,  5.744,  5.744,  5.743,  5.742,
    5.742,  5.741,  5.74 ,  5.74 ,  5.739,  5.739,  5.738,  5.737],
  "almost_too_high":[65.228, 48.381, 36.336, 27.723, 21.563, 17.156, 14.001, 11.743,
    10.124,  8.964,  8.13 ,  7.531,  7.099,  6.787,  6.561,  6.396,
     6.274,  6.185,  6.118,  6.067,  6.029,  5.998,  5.974,  5.955,
     5.938,  5.924,  5.912,  5.902,  5.892,  5.884,  5.875,  5.868,
     5.861,  5.854,  5.848,  5.842,  5.836,  5.831,  5.825,  5.82 ,
     5.815,  5.811,  5.806,  5.802,  5.798,  5.794,  5.79 ,  5.786,
     5.783,  5.779,  5.776,  5.773,  5.77 ,  5.767,  5.764,  5.761,
     5.759,  5.756,  5.754,  5.751,  5.749,  5.747,  5.745,  5.743,
     5.741,  5.739,  5.737,  5.735,  5.734,  5.732,  5.731,  5.729,
     5.728,  5.726,  5.725,  5.724,  5.723,  5.721,  5.72 ,  5.719,
     5.718,  5.717,  5.716,  5.715,  5.714,  5.713,  5.713,  5.712,
     5.711,  5.71 ,  5.71 ,  5.709,  5.708,  5.708,  5.707,  5.706,
     5.706,  5.705,  5.705,  5.704,  5.704,  5.703,  5.703,  5.702,
     5.702,  5.702,  5.701,  5.701,  5.7  ,  5.7  ,  5.7  ,  5.699,
     5.699,  5.699,  5.699,  5.698,  5.698,  5.698,  5.698,  5.697,
     5.697,  5.697,  5.697,  5.696,  5.696,  5.696,  5.696,  5.696,
     5.696,  5.695,  5.695,  5.695,  5.695,  5.695,  5.695,  5.695,
     5.694,  5.694,  5.694,  5.694,  5.694,  5.694,  5.694,  5.694,
     5.694,  5.693,  5.693,  5.693,  5.693,  5.693,  5.693,  5.693,
     5.693,  5.693,  5.693,  5.693,  5.693,  5.693,  5.693,  5.693,
     5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692,
     5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692,
     5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692,
     5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692,
     5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692,  5.692],
    "too_high":[   65.228,    67.3  ,    69.447,    71.672,    73.977,    76.367,
      78.842,    81.407,    84.065,    86.818,    89.67 ,    92.625,
      95.687,    98.858,   102.142,   105.545,   109.069,   112.72 ,
     116.501,   120.417,   124.474,   128.675,   133.026,   137.533,
     142.201,   147.035,   152.041,   157.226,   162.596,   168.157,
     173.917,   179.881,   186.058,   192.455,   199.079,   205.94 ,
     213.044,   220.402,   228.021,   235.911,   244.082,   252.544,
     261.307,   270.381,   279.778,   289.509,   299.586,   310.022,
     320.828,   332.019,   343.608,   355.608,   368.036,   380.904,
     394.231,   408.031,   422.321,   437.12 ,   452.444,   468.313,
     484.746,   501.763,   519.385,   537.633,   556.529,   576.097,
     596.36 ,   617.343,   639.072,   661.573,   684.874,   709.003,
     733.989,   759.863,   786.656,   814.401,   843.132,   872.884,
     903.693,   935.597,   968.634,  1002.845,  1038.272,  1074.958,
    1112.947,  1152.285,  1193.022,  1235.206,  1278.889,  1324.123,
    1370.965,  1419.472,  1469.702,  1521.716,  1575.578,  1631.355,
    1689.113,  1748.923,  1810.858,  1874.994,  1941.408,  2010.182,
    2081.4  ,  2155.148,  2231.516,  2310.598,  2392.489,  2477.29 ,
    2565.104,  2656.038,  2750.203,  2847.713,  2948.688,  3053.251,
    3161.528,  3273.653,  3389.761,  3509.994,  3634.5  ,  3763.429,
    3896.939,  4035.192,  4178.357,  4326.609,  4480.129,  4639.102,
    4803.724,  4974.195,  5150.723,  5333.523,  5522.817,  5718.837,
    5921.821,  6132.017,  6349.682,  6575.08 ,  6808.486,  7050.185,
    7300.471,  7559.65 ,  7828.037,  8105.96 ,  8393.758,  8691.78 ,
    9000.392,  9319.968,  9650.898,  9993.586, 10348.45 , 10715.922,
   11096.449, 11490.497, 11898.545, 12321.091, 12758.649, 13211.754,
   13680.957, 14166.831, 14669.967, 15190.98 , 15730.503, 16289.196,
   16867.739, 17466.837, 18087.221, 18729.646, 19394.897, 20083.783,
   20797.145, 21535.853, 22300.806, 23092.938, 23913.213, 24762.633,
   25642.232, 26553.082, 27496.294, 28473.018, 29484.445, 30531.807,
   31616.381, 32739.489, 33902.5  , 35106.833, 36353.955, 37645.386,
   38982.701, 40367.53 , 41801.561, 43286.543, 44824.285, 46416.662,
   48065.615, 49773.154, 51541.361, 53372.392, 55268.477, 57231.93 ,
   59265.143, 61370.595]
}

const textColorsLearningRates = {
  "low": "#fff",
  "ideal": "#000",
  "almost_too_high": '#fff',
  "too_high":'#000'
}

const keyToLabelLearningRates = {
  "low": "low",
  "ideal": "ideal",
  "almost_too_high": 'almost too <br> high',
  "too_high":'too high'
}

var layout = {
  grid: {rows: 1, columns: 2, pattern: 'independent'},
};

var xDataLearningRates = [...Array(200).keys()]

var axsX = {
  low: 'x1',
  ideal: 'x1',
  almost_too_high: 'x1',
  too_high: 'x2'
}

var axsY = {
  low: 'y1',
  ideal: 'y1',
  almost_too_high: 'y1',
  too_high: 'y2'
}

export const markerLinesLearningRates = {
  data: Object.keys(yDataLearningRates).map((key, index) => ({
    x: xDataLearningRates,
    y: yDataLearningRates[key],
    type: `scatter`,
    mode: 'lines',
    xaxis: axsX[key],
    yaxis: axsY[key],
    name: keyToLabelLearningRates[key],
    hoverlabel: {
      font: {color: textColorsLearningRates[key]}
    },
    hovertemplate:
            "loss (MSE): %{y}k<br>" +
            "epoch: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  layout: {
    grid: {rows: 1, columns: 2, pattern: 'independent'},
    autosize: true,
    hovermode: 'closest',
    yaxis2: {
      gridcolor: "rgba(255,255,255,0.05)",
      zeroline: true,
      showline: true,
      fixedrange: true,
      autotick: true,
      ticks: '',
      showticklabels: true
        },
        xaxis: {
          title: "Epoch",
          autorange: true,
          showgrid: true,
          gridcolor: "rgba(255,255,255,0.05)",
          zeroline: true,
          showline: true,
          fixedrange: true,
          autotick: true,
          ticks: '',
          showticklabels: true
          },
    xaxis2: {
        title: "Epoch",
        autorange: true,
        fixedrange: true,
        showgrid: true,
        gridcolor: "rgba(255,255,255,0.05)",
        zeroline: true,
        showline: true,
        autotick: true,
        ticks: '',
        showticklabels: true
        },
    paper_bgcolor: `rgba(0, 0, 0, 0)`,
    plot_bgcolor: `rgba(0, 0, 0, 0)`,
    font: {
      color: 'white',
      size: 16,
      // The next 3 options make the plot responsive.
    }
  },
  props: {
    xAxisTitle: "Epoch",
    yAxisTitle: "MSE (k)",
    plotTitle: "Different Learning Rates",
    fixedRange: true,
  }
}