import { treatAsUTC } from "../../../utils"

const colors = ['#33638D','#54C667','#6a1b9a','#FF8900']
const threeFunctionColors =  ['#54C667','#FF8900','#6a1b9a','#33638D']
// prettier-ignore

const dataXMarkersOnly = {
  markers: ['1', '2', '3', '3', '4', '4', '5'],
}

const dataX = {
  markers: ['1', '2', '3', '3', '4', '4', '5'],
  lines: ['1','2','3','4','5'],
}

const dataY = {
  markers: [80,170,100,220,200,270,500], 
  lines: [60,120,180,240,300],
}

const dataXThreeFunctions = {
  f: ['1','2','3','4','5'],
  g: ['1','2','3','4','5'],
  h: ['1','2','3','4','5'],
  data: ['1', '2', '3', '3', '4', '4', '5'],
}

const dataYThreeFunctions = {
  f: [60, 120, 180, 240, 300],
  g: [70, 140, 210, 280, 350],
  h: [55, 105, 155, 205, 255],
  data: [80,170,100,220,200,270,500], 
}

const threeFunctionModes = {
  f: "lines",
  g: "lines",
  h: "lines",
  data: "markers" 
}

const threeFunctionTextColors = {
  f: "#000",
  g: "#000",
  h: "#fff",
  data: "#fff" 
}

const complexitiesTextColors = {
  'O(n^2)': "#fff",
  'O(n^2.4)': "#000",
  'O(n^3)': "#fff",
}

const modes = {
  markers: "markers",
  lines: "lines" 
}

const textColors = {
  markers: "#fff",
  lines: "#000",
  third: '#000'
}

export const markerData = {
  data: Object.keys(dataXMarkersOnly).map((key, index) => ({
    x: dataX[key],
    y: dataY[key],
    type: `scatter`,
    mode: modes[key],
    name: key,
    showlegend: false,
    hoverlabel: {
      font: {color: threeFunctionTextColors[key]}
    },
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    plotTitle: "Housing Prices"
  }
}

export const markerLinesData = {
  data: Object.keys(dataX).map((key, index) => ({
    x: dataX[key],
    y: dataY[key],
    type: `scatter`,
    mode: modes[key],
    name: key,
    hoverlabel: {
      font: {color: textColors[key]}
    },
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: colors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    plotTitle: "Housing Prices"
  }
}

export const threeLinesData = {
  data: Object.keys(dataXThreeFunctions).map((key, index) => ({
    x: dataXThreeFunctions[key],
    y: dataYThreeFunctions[key],
    type: `scatter`,
    mode: threeFunctionModes[key],
    name: key,
    hoverlabel: {
      font: {color: threeFunctionTextColors[key]}
    },
    hovertemplate:
            "price: %{y}k<br>" +
            "#bedrooms: %{x}<br>" +
            "<extra></extra>",
    marker: { 
      size:15,
      color: threeFunctionColors[index],
      line: {
        color: '#fff',
        width: 1
    }},
  })),
  props: {
    yAxisTitle: "price in thousand $",
    xAxisTitle: "number of bedrooms",
    plotTitle: "Housing Prices"
  }
}