import { markerData, somr_for_every_m_and_b_with_minimum, mse_plot_partial_derivative_m, mse_plot_partial_derivative_b, quadraticPlot, quadraticWithDerivative, quadraticWithDerivativeWithPoint, msePlotWithPoints, markerLinesLearningRates } from "../../../../src/content/machine_learning_models/data/linearRegressionData";
import * as React from 'react';
export default {
  markerData,
  somr_for_every_m_and_b_with_minimum,
  mse_plot_partial_derivative_m,
  mse_plot_partial_derivative_b,
  quadraticPlot,
  quadraticWithDerivative,
  quadraticWithDerivativeWithPoint,
  msePlotWithPoints,
  markerLinesLearningRates,
  React
};