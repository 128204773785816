import { polynomialData, polynomialPredUnderFitNoAnnotations, polynomialPredUnderFit, polynomialPredFit, polynomialPredOverFit, polynomialPredDegreeInteractive, polynomialPredUnderFitInteractive, polynomialPredFitInteractive, polynomialPredOverFitInteractive, biasVarianceTradeoff, trueRelationshipInteractive } from "../../../../src/content/model_optimization/data/biasVarianceData";
import * as React from 'react';
export default {
  polynomialData,
  polynomialPredUnderFitNoAnnotations,
  polynomialPredUnderFit,
  polynomialPredFit,
  polynomialPredOverFit,
  polynomialPredDegreeInteractive,
  polynomialPredUnderFitInteractive,
  polynomialPredFitInteractive,
  polynomialPredOverFitInteractive,
  biasVarianceTradeoff,
  trueRelationshipInteractive,
  React
};