const colors = ['#33638D', '#54C667', '#9628d9', '#FF8900'];

const range = (from, to, stepsize) => {
  let zeroOffset = from > 0 ? 0 : -from;

  const diff = Math.floor((to - from) / stepsize);
  // -20 to 50

  let range = [...Array(diff).keys()].map(x => parseFloat((x * stepsize - zeroOffset).toFixed(2)));

  return range;
};

const hoverTemplatePoint = 'x: %{x}<br>' + 'y: %{y}<br>' + '<extra></extra>';

const f2SliderRange = range(-0.5, 1.5, 0.1); // [-0.5, -0.4, -0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5]

const f2 = x => (x < 2 ? 0.5 * x : 2 * x - 3);
const f2X = range(-2, 6, 1);
const f2Y = f2X.map(x => f2(x));

const f2XDetailed = range(-2, 6, 0.5);

const f2BaseData = {
  x: f2XDetailed,
  y: f2XDetailed.map(x => f2(x)),
  type: 'scatter',
  mode: 'lines+text',
  name: 'f2',
  text: f2XDetailed.map((_, idx) => (idx == 11 ? 'f2(x)' : '')),
  textfont: {
    family: 'Inter',
    size: 18,
    color: colors[0],
  },
  textposition: 'top left',
  color: colors[0],
  hovertemplate: hoverTemplatePoint,
};

const f2SliderLayout = {
  transition: {
    duration: 750,
    easing: 'cubic-in-out',
  },
  xaxis: {
    range: [-2, 5],
    showgrid: true,
    gridcolor: 'rgba(255,255,255,0.05)',
    zeroline: true,
    fixedrange: true,
    showline: true,
    autotick: true,
    ticks: '',
    showticklabels: true,
  },
  yaxis: {
    range: [-1, 5],
    gridcolor: 'rgba(255,255,255,0.05)',
    zeroline: true,
    fixedrange: true,
    showline: true,
    autotick: true,
    ticks: '',
    showticklabels: true,
  },
  margin: { t: 80, r: 80, b: 80, l: 80 },
  paper_bgcolor: `rgba(0, 0, 0, 0)`,
  plot_bgcolor: `rgba(0, 0, 0, 0)`,
  font: {
    color: `rgba(255,255,255,0.5)`,
    size: 16,
  },
};

export const f2FunctionBase = {
  data: [f2BaseData],
  props: {
    xAxisTitle: 'x',
    yAxisTitle: 'y',
  },
};

const f2SubgradientDataAtZero = {
  x: f2X,
  y: f2X,
  type: 'scatter',
  mode: 'lines+text',
  name: 'g2',
  color: colors[1],
  text: f2X.map((_, idx) => (idx == 4 ? 'g2(x)' : '')),
  textfont: {
    family: 'Inter',
    size: 18,
    color: colors[1],
  },
  textposition: 'top left',
  line: {
    color: colors[1],
  },
  hovertemplate: hoverTemplatePoint,
};

const f2SubgradientXShift = {
  x: f2X.map(x => x + 2),
  y: f2X,
  type: 'scatter',
  mode: 'lines+text',
  name: 'g2',
  color: colors[1],
  text: f2X.map((_, idx) => (idx == 4 ? 'g2(x - x0)' : '')),
  textfont: {
    family: 'Inter',
    size: 18,
    color: colors[1],
  },
  textposition: 'top left',
  line: {
    color: colors[1],
  },
  hovertemplate: hoverTemplatePoint,
};

const f2SubgradientXYShift = {
  x: f2X.map(x => x + 2),
  y: f2X.map(x => x + 1),
  type: 'scatter',
  text: f2X.map((_, idx) => (idx == 4 ? 'f2(x0) + g2(x - x0)' : '')),
  textfont: {
    family: 'Inter',
    size: 18,
    color: colors[1],
  },
  textposition: 'top left',
  mode: 'lines+text',
  name: 'g2',
  line: {
    color: colors[1],
  },
  hovertemplate: hoverTemplatePoint,
};

export const f2SubgradientPlotZero = {
  data: [f2BaseData, f2SubgradientDataAtZero],

  layout: f2SliderLayout,

  props: {
    xRange: [-2, 5],
    yRange: [-1, 1],
    fixedRange: true,
  },
};

/*
export const f2SubgradientPlotXShift = {

    data:[
      f2BaseData,
      f2SubgradientXShift
    ], 
  
    layout: f2SliderLayout,
  
    props: {
  
      xRange: [-2,5],
      yRange: [-1,1],
      fixedRange: true,
    },
  
}
  
export const f2SubgradientPlotXYShift = {

    data:[
      f2BaseData,
      f2SubgradientXYShift
    ], 
  
    layout: f2SliderLayout,
  
    props: {
  
      xRange: [-2,5],
      yRange: [-1,1],
      fixedRange: true,
    },
  
}*/

let subgradientIdx = 0;
const subgradientStateText = ['g(x)', 'g(x - x0)', 'f(x0) - g(x - x0)'];
const subgradientData = [f2SubgradientDataAtZero, f2SubgradientXShift, f2SubgradientXYShift];

const changeSubgradient = data => {
  subgradientIdx = (subgradientIdx + 1) % 3;
  const newSubgradientData = subgradientData[subgradientIdx];
  const newData = [data[0], newSubgradientData];

  return newData;
};

export const f2SubgradientPlotInteractive = {
  data: [f2BaseData, f2SubgradientDataAtZero],

  layout: f2SliderLayout,

  props: {
    xRange: [-2, 5],
    yRange: [-1, 1],
    fixedRange: true,

    changeDataFunctions: { 'next step': changeSubgradient },
  },
};

// ---------- code ----------

export const setupCodeSubgradientDescent = `import numpy as np
np.random.seed(42)

# from the gradient descent for linear regression article
def create_function(theta):
    def f(X_b):
        return np.dot(X_b,theta)
    return f

# from the gradient descent for linear regression article
def add_intercept_ones(X):
    intercept_ones = np.ones((len(X),1)) # results in array( [ [1],..,[1] ] )
    X_b = np.c_[intercept_ones,X]
    return X_b

# from the standardization article
def standardize(X_train, X_test):
    mean = np.mean(X_train)
    std = np.std(X_train)
    X_train_s = (X_train - mean) / std 
    X_test_s = (X_test - mean) / std 
    return X_train_s, X_test_s, mean, std

def get_elastic_mse_function(a2=1, a1=0):

    def elastic_mse(y, y_predicted, theta):
        error = y - y_predicted
        return (
            1 / (y.size) * np.dot(error.T, error) # mse
            + a2 * np.dot(theta, theta)           # l2-penalty
            + a1 * np.sum(np.abs(theta))          # l1-penalty
        )

    return elastic_mse

def get_elastic_gradient_function(a2=1, a1=0):

    def elastic_gradient(X, y, y_pred, theta):
        return (
            -(2 / y.size) * X.T.dot(y - y_pred) # gradient of mse
            + 2 * a2 * theta                    # gradient of l2-penalty
            + a1 * np.sign(theta)               # subgradient of-l1 penalty
        )

    return elastic_gradient
    
def subgradient_descent(X, y, theta, criterion, subgradient_function, number_of_iterations, learning_rate):
    X_b = add_intercept_ones(X)
    for i in range(number_of_iterations):

        # predict and calculate loss
        f = create_function(theta)  # create the current function
        y_predicted = f(X_b)  # predict our entire x
        loss = criterion(y, y_predicted, theta)  # calculate the error

        # perform optimization
        subgradient = subgradient_function(X_b, y, y_predicted, theta)  # calculate gradient
        theta = theta - learning_rate * subgradient  # adjust m and b

    return theta
    
X = np.array([1.25, 1.  , 0.75, 1.5, 1.75, 1.5 , 0.75])
y = np.array([40. , 42. , 46. , 37., 40. , 38. , 39.8])

X_train, X_test = X[:4], X[4:]
y_train, y_test = y[:4], y[4:]

X_train_s, X_test_s, _, _ = standardize(X_train, X_test)`;

export const setupCodeSubgradientDescentEps = `import numpy as np
np.random.seed(42)

# from the gradient descent for linear regression article
def create_function(theta):
    def f(X_b):
        return np.dot(X_b,theta)
    return f

# from the gradient descent for linear regression article
def add_intercept_ones(X):
    intercept_ones = np.ones((len(X),1)) # results in array( [ [1],..,[1] ] )
    X_b = np.c_[intercept_ones,X]
    return X_b

# from the standardization article
def standardize(X_train, X_test):
    mean = np.mean(X_train)
    std = np.std(X_train)
    X_train_s = (X_train - mean) / std 
    X_test_s = (X_test - mean) / std 
    return X_train_s, X_test_s, mean, std

def get_elastic_mse_function(a2=1, a1=0):

    def elastic_mse(y, y_predicted, theta):
        error = y - y_predicted
        return (
            1 / (y.size) * np.dot(error.T, error) # mse
            + a2 * np.dot(theta, theta)           # l2-penalty
            + a1 * np.sum(np.abs(theta))          # l1-penalty
        )

    return elastic_mse

def get_elastic_gradient_function(a2=1, a1=0):

    def elastic_gradient(X, y, y_pred, theta):
        return (
            -(2 / y.size) * X.T.dot(y - y_pred) # gradient of mse
            + 2 * a2 * theta                    # gradient of l2-penalty
            + a1 * np.sign(theta)               # subgradient of-l1 penalty
        )

    return elastic_gradient
    
def subgradient_descent(X, y, theta, criterion, subgradient_function, number_of_iterations, learning_rate, eps):
    X_b = add_intercept_ones(X)
    for i in range(number_of_iterations):

        # predict and calculate loss
        f = create_function(theta)  # create the current function
        y_predicted = f(X_b)  # predict our entire x
        loss = criterion(y, y_predicted, theta)  # calculate the error

        # perform optimization
        subgradient = subgradient_function(X_b, y, y_predicted, theta)  # calculate gradient
        theta = theta - learning_rate * subgradient  # adjust m and b

        theta[theta < eps] = 0

    return theta
    
X = np.array([1.25, 1.  , 0.75, 1.5, 1.75, 1.5 , 0.75])
y = np.array([40. , 42. , 46. , 37., 40. , 38. , 39.8])

X_train, X_test = X[:4], X[4:]
y_train, y_test = y[:4], y[4:]

X_train_s, X_test_s, _, _ = standardize(X_train, X_test)`;

export const setupCodeSubgradientDescentReinforcement = `import numpy as np
np.random.seed(42)

# from the gradient descent for linear regression article
def create_function(theta):
    def f(X_b):
        return np.dot(X_b,theta)
    return f

# from the gradient descent for linear regression article
def add_intercept_ones(X):
    intercept_ones = np.ones((len(X),1)) # results in array( [ [1],..,[1] ] )
    X_b = np.c_[intercept_ones,X]
    return X_b

# from the standardization article
def standardize(X_train, X_test):
    mean = np.mean(X_train)
    std = np.std(X_train)
    X_train_s = (X_train - mean) / std 
    X_test_s = (X_test - mean) / std 
    return X_train_s, X_test_s, mean, std

def get_elastic_mse_function(a2=1, a1=0):

    def elastic_mse(y, y_predicted, theta):
        error = y - y_predicted
        return (
            1 / (y.size) * np.dot(error.T, error) # mse
            + a2 * np.dot(theta, theta)           # l2-penalty
            + a1 * np.sum(np.abs(theta))          # l1-penalty
        )

    return elastic_mse

def get_elastic_gradient_function(a2=1, a1=0):

    def elastic_gradient(X, y, y_pred, theta):
        return (
            -(2 / y.size) * X.T.dot(y - y_pred) # gradient of mse
            + 2 * a2 * theta                    # gradient of l2-penalty
            + a1 * np.sign(theta)               # subgradient of-l1 penalty
        )

    return elastic_gradient
  
X = np.array([
  3.37, 8.56, 6.59, 5.39, 1.4, 1.4, 0.52, 7.8, 5.41, 6.37, 0.19, 8.73, 7.49, 1.91, 1.64, 1.65, 2.74,
  4.72, 3.89, 2.62, 5.51, 1.26, 2.63, 3.3, 4.1, 7.07, 1.8, 4.63, 5.33, 0.42, 5.47, 1.53, 0.59, 8.54,
  8.69, 7.28, 2.74, 0.88, 6.16, 3.96, 1.1, 4.46, 0.31, 8.18, 2.33, 5.96, 2.81, 4.68, 4.92, 1.66,
  1.25, 1, 0.75, 1.5, 1.75, 1.5, 0.75,
])

y = np.array([
  22.75, 21.7, 20.85, 20.67, 21, 22.01, 22.99, 22.62, 21.53, 18.65, 24.29, 21.03, 20.23, 23.44,
  24.18, 24.04, 20.98, 20.81, 21.96, 23.47, 20.42, 22.83, 20.69, 20.2, 22.52, 22.87, 22.6, 22.59,
  21.57, 22.83, 21.54, 24.93, 23.51, 23.55, 18.03, 22.19, 22.22, 22.95, 21.12, 18.84, 22.89, 21.78,
  25.74, 20.64, 21.27, 20.34, 23.28, 21.68, 20.47, 23.47, 20, 21, 23, 18.5, 20, 18.5, 19.9,
])
  
X_train, X_test = X[10:], X[:10]
y_train, y_test = y[10:], y[:10]

X_train_s, X_test_s, _, _ = standardize(X_train, X_test)`;

const polyDataX = [
  3.37, 8.56, 6.59, 5.39, 1.4, 1.4, 0.52, 7.8, 5.41, 6.37, 0.19, 8.73, 7.49, 1.91, 1.64, 1.65, 2.74,
  4.72, 3.89, 2.62, 5.51, 1.26, 2.63, 3.3, 4.1, 7.07, 1.8, 4.63, 5.33, 0.42, 5.47, 1.53, 0.59, 8.54,
  8.69, 7.28, 2.74, 0.88, 6.16, 3.96, 1.1, 4.46, 0.31, 8.18, 2.33, 5.96, 2.81, 4.68, 4.92, 1.66,
  1.25, 1, 0.75, 1.5, 1.75, 1.5, 0.75,
];

const polyDataY = [
  22.75, 21.7, 20.85, 20.67, 21, 22.01, 22.99, 22.62, 21.53, 18.65, 24.29, 21.03, 20.23, 23.44,
  24.18, 24.04, 20.98, 20.81, 21.96, 23.47, 20.42, 22.83, 20.69, 20.2, 22.52, 22.87, 22.6, 22.59,
  21.57, 22.83, 21.54, 24.93, 23.51, 23.55, 18.03, 22.19, 22.22, 22.95, 21.12, 18.84, 22.89, 21.78,
  25.74, 20.64, 21.27, 20.34, 23.28, 21.68, 20.47, 23.47, 20, 21, 23, 18.5, 20, 18.5, 19.9,
];

/*const lassoRandMB = [-4.588, -4.402, -4.217, -4.032, -3.847, -3.662, -3.476, -3.291,
  -3.106, -2.921, -2.736, -2.55 , -2.365, -2.18 , -1.995, -1.809,
  -1.624, -1.439, -1.254, -1.069, -0.883, -0.698, -0.513, -0.328,
  -0.142,  0.043,  0.228,  0.413,  0.598,  0.784,  0.969,  1.154,
   1.339,  1.524,  1.71 ,  1.895,  2.08 ,  2.265,  2.451,  2.636,
   2.821,  3.006,  3.191,  3.377,  3.562,  3.747,  3.932,  4.117,
   4.303,  4.488]

function mseLasso(points: number[], preds: number[], m: number, b: number, a: number = 0.0001) {
    let result = 0;
    for (let i = 0; i < points.length; i++) {
      let curPoint = points[i];
      let curPred = preds[i];
      result += (curPred - curPoint) ** 2;
    }
    result = result / points.length;
    result += a * Math.abs(m);
    result += a * Math.abs(b);
    return result;
}

function getZLasso(m: number, b: number, points_x: number[], points_y: number[]) {
  let f_values = points_x.map(x => f(m, b, x));

  let z = mseLasso(points_y, f_values, m, b);

  return z;
}

const lassoLossRandZ = lassoRandMB.map(curB =>
  lassoRandMB.map(curM => getZLasso(curM, curB, polyDataX, polyDataY)),
);

const lassoSubGDRandHist3dData = [
  {
    x: lassoRandMB,
    y: lassoRandMB,
    z: lassoLossRandZ,
    hoverlabel: {
      font: { color: '#fff' },
      bgcolor: '#17225D',
    },
    hovertemplate: 'm: %{x}<br>' + 'b: %{y}<br>' + 'Lasso-MSE: %{z}<br>' + '<extra></extra>',
    colorscale: 'YlGnBu',
    type: `surface`,
    contours: {
      z: {
        show: true,
        usecolormap: true,
        highlightcolor: `white`,
        project: { z: true },
      },
    },
  },
  {
    x: [thetaHistLassoSubGD[0][1]],
    y: [thetaHistLassoSubGD[0][0]],
    z: [thetaHistLassoLossSubGD[0]],
    hoverlabel: {
      font: { color: '#000' },
      bgcolor: colors[3],
    },
    hovertemplate: 'm: %{x}<br>' + 'b: %{y}<br>' + 'Lasso-MSE: %{z}<br>' + '<extra></extra>',
    showlegend: false,
    marker: {
      size: 12,
      color: colors[3],
      line: {
        color: 'rgba(217, 217, 217, 0.14)',
        width: 0.5,
      },
      opacity: 1,
    },
    line: {
      //size: 12,
      color: colors[3],
    },
    type: 'scatter3d',
    mode: `lines`,
  },
  {
    x: [thetaHistLassoSubGD[0][1]],
    y: [thetaHistLassoSubGD[0][0]],
    z: [thetaHistLassoLossSubGD[0]],
    text: [thetaHistLassoLossSubGD[0]].map(x => (x - 1000).toFixed(2)),
    hoverlabel: {
      font: { color: '#000' },
      bgcolor: colors[3],
    },
    hovertemplate: 'm: %{x}<br>' + 'b: %{y}<br>' + 'Lasso-MSE: %{text}<br>' + '<extra></extra>',
    showlegend: false,
    marker: {
      size: 12,
      color: colors[3],
      line: {
        color: 'rgba(217, 217, 217, 0.14)',
        width: 0.5,
      },
      opacity: 1,
    },
    line: {
      //size: 12,
      color: colors[3],
    },
    type: 'scatter3d',
    mode: `markers`,
  },
];*/
