import { olsVsRidgeData, olsVsRidgeDataTrainTest, olsVsRidgeDataTrainTestOls, olsVsRidgeDataTrainTestOlsRidge, olsWiggle, ridgeWiggle, ridgeHistPlot } from "../../../../src/content/machine_learning_models/data/lassoRidgeData";
import * as React from 'react';
export default {
  olsVsRidgeData,
  olsVsRidgeDataTrainTest,
  olsVsRidgeDataTrainTestOls,
  olsVsRidgeDataTrainTestOlsRidge,
  olsWiggle,
  ridgeWiggle,
  ridgeHistPlot,
  React
};