import { markerData, markerLinesData, threeLinesData, somr_for_every_m_and_b, somr_for_every_m_and_b_with_minimum, markerLinesDataIdeal, complexitiesData, complexitiesDataWithoutO3 } from "../../../../src/content/machine_learning_models/data/linearRegressionData";
import * as React from 'react';
export default {
  markerData,
  markerLinesData,
  threeLinesData,
  somr_for_every_m_and_b,
  somr_for_every_m_and_b_with_minimum,
  markerLinesDataIdeal,
  complexitiesData,
  complexitiesDataWithoutO3,
  React
};