import { absoluteFunctionBase, absoluteFunctionWithGrad, absoluteFunctionSlider, olsVsRidgeData, olsVsRidgeDataTrainTestOlsRidge, lassoSubGDHistPlot, lassoCDHistPlot } from "../../../../src/content/machine_learning_models/data/lassoRidgeData";
import * as React from 'react';
export default {
  absoluteFunctionBase,
  absoluteFunctionWithGrad,
  absoluteFunctionSlider,
  olsVsRidgeData,
  olsVsRidgeDataTrainTestOlsRidge,
  lassoSubGDHistPlot,
  lassoCDHistPlot,
  React
};